.homeSidebar {
    height: 450px;
    text-align: left;
    background: currentColor;
    border-radius: 15px 15px 30px 30px;
}
.forRightScroll{
    height: 520px;
}
.forOverflow div{
    margin-left: 10%;
    margin-top: 10%;
}
.homeSidebar h6 a{
    border-radius: 20px;
    text-decoration: none;
    padding: 10px 32px;
    text-align: center;
    background-color: rgb(243 243 243);
    display: block;
}
.homeSidebar a:hover{
    background-color: rgba(230, 227, 227, 0.948);
}
.homeSidebar h6{
margin: 40px 36px;
}
.homeSidebar svg{
    margin-right: 10px;
}
