/* VenuDetails.css */

.venue-details-container {
    max-width: 900px; /* Limit the width of the container */
    margin: 85px auto; /* Center the container */
    padding: 20px; /* Add some padding */
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .venue-list {
    margin-top: 20px; /* Space above the venue list */
    padding: 0; /* Remove padding */
    list-style-type: none; /* Remove default list styles */
  }
  
  .venue-item {
    margin-bottom: 20px; /* Space between items */
    padding: 15px; /* Padding around each item */
    background-color: #fff; /* White background for each venue item */
    border: 1px solid #e0e0e0; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    transition: box-shadow 0.2s ease; /* Smooth transition for hover effect */
    text-align: left; /* Left align text */
  }
  
  .venue-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  }
  
  .venue-description {
    font-size: 1.2em; /* Slightly larger font for description */
    margin: 10px 0; /* Space above and below the description */
    color: #333; /* Darker color for better readability */
  }
  
  .venue-image {
    max-width: 100%; /* Responsive image */
    width: -webkit-fill-available;
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners for images */
    display: block; /* Make the image a block element */
    margin: 0 auto; /* Center the image horizontally */
  }
  