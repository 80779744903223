.contactBG{
    background-color: #4b5769;
    margin-top: 5%;
}
.form-label {
    margin-bottom: .5rem;
}
label {
    display: inline-block;
    color: #180101;
}