/* ManuallyAddPaper.css */

/* Container */
.add-paper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #f4f7fc;
}

.add-paper-form {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
}

/* Title */
.form-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Form Groups */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

.form-group select {
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="none" d="M1 1h14v14H1z" /%3E%3Cpath d="M4.5 6l3.5 3.5L11.5 6h-7z" /%3E%3C/svg%3E');
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
}

.form-group input[type='file'] {
  border: none;
  padding: 0;
  background-color: #f4f7fc;
}

/* Authors Section */
.authors-section {
  margin-bottom: 20px;
}

.authors-section h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.author {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.author-input {
  flex: 1 1 100%;
}

.author-input input {
  width: 100%;
}

.remove-author {
  background-color: #e74c3c;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-author {
  background-color: #3498db;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

/* Upload Button */
.submit-btn {
  background-color: #2ecc71;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s;
}

.submit-btn:hover {
  background-color: #27ae60;
}

/* Responsive Design */
@media (max-width: 768px) {
  .add-paper-form {
    padding: 20px;
  }

  .form-title {
    font-size: 20px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 14px;
  }

  .author {
    flex-direction: column;
  }

  .author-input input {
    margin-bottom: 10px;
  }

  .remove-author {
    width: 100%;
    margin-top: 10px;
  }

  .add-author {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .add-paper-form {
    padding: 15px;
  }

  .form-title {
    font-size: 18px;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 12px;
  }

  .submit-btn {
    padding: 10px;
  }

  .remove-author,
  .add-author {
    width: 100%;
  }
}
