.add-tourist {
    width: 90%;
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-tourist-title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tourist-form-group {
    margin-bottom: 20px;
  }
  
  .tourist-label {
    font-size: 16px;
    color: #555;
    margin-bottom: 8px;
    display: block;
  }
  
  .tourist-input, .tourist-textarea, .tourist-file-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .tourist-textarea {
    resize: vertical;
  }
  
  /* Button group styling */
  .button-group {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  
  .add-more-button, .save-button {
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-more-button {
    background-color: #007bff;
  }
  
  .save-button {
    background-color: #4caf50;
  }
  
  .save-button:hover, .add-more-button:hover {
    opacity: 0.9;
  }
  