

/* committee.css */

.committee-container {
 
  margin-top: 80px;
  max-width: 900px;
  
}


/* Page Title Styling */

 /* title design css */
 .program-schedule h4 {


  text-align: center;
  font-size: 30px;
  font-weight: 300;
  /* color: #222; */
  color: blue;
  letter-spacing: 1px;
  text-transform: uppercase;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
  padding: 10px 75px;

}

.program-schedule h4:after,
.program-schedule h4:before {
  content: " ";
  display: block;
  border-bottom: 1px solid #c50000;
  border-top: 1px solid #c50000;
  height: 5px;
  background-color: #f8f8f8;
}




/* Other styles */
.committee-container {

  padding: 2rem 0;
  background-color: #f9f9f9;
}

/* .committee-section {
  margin-bottom: 2rem;
} */

.committee-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #d9534f; /* Red like the titles in your design */
  text-align: center;
  border-bottom: 3px solid #007bff; /* Blue underline */
  display: inline-block;
  margin-bottom: 10px;
  text-decoration: none;
}

.members-list {
  margin-left: 1rem;
}

.member-info {
  margin-bottom: 1rem;
  padding-left: 1rem;
  border-left: 3px solid #007bff; /* Blue vertical line next to members */
}

.member-name {
  font-size: 1.0rem;
  font-weight: 600;
  color: #333;
}

.member-email {
  font-size: 0.9rem;
  color: #666;
}

.member-info p {
  margin: 0.3rem 0;
}

/* Ensure the page is responsive */
@media (max-width: 768px) {
  .committee-title {
      font-size: 1.5rem;
  }

  .member-name {
      font-size: 1rem;
  }

  .member-email {
      font-size: 0.85rem;
  }
}
