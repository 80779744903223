.registration-form {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.form-title {
    font-size: 2rem;
    color: #343a40;
    text-align: center;
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 20px; /* Adds space between each input */
}

.form-label {
    font-weight: 600;
    color: #495057;
    min-width: 150px;
}

.category-select, .international-input, .local-input {
    flex: 1;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.category-select:focus, .international-input:focus, .local-input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.btn {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.btn:hover {
    background-color: #0056b3;
}
.btn.save {
    margin-left: 4px;
    background-color: #28a745;
    color: white;
    border: none;
}

.btn.save:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(40, 167, 69, 0.2);
}