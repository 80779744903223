.tourist-list {
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tourist-list-title {
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tourist-list-items {
    list-style-type: none;
    padding: 0;
  }
  
  .tourist-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .tourist-description {
    font-size: 18px;
    color: #555;
    margin-right: 10px;
    flex: 1;
  }
  
  .tourist-image {
    width: 100px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: white;
    font-size: 16px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .remove-button:hover {
    background-color: #ff3333;
  }
  
  .no-data {
    font-size: 18px;
    color: #777;
    text-align: center;
    margin-top: 20px;
  }
  