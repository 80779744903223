/* support.css */
.body{
  background:red;
}
.support-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjusts the container to the exact center */
  padding: 20px;
  max-width: 1200px;
  font-family: 'Arial', sans-serif;
  background-color: #f8f8f8;  /* Optional: Add a background color */
  border-radius: 8px;         /* Optional: Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}
  /* title design css */
  .support-container h4 {

    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #222;
    letter-spacing: 1px;
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    padding: 10px 75px;

}

.support-container h4:after,
.support-container h4:before {
    content: " ";
    display: block;
    border-bottom: 1px solid #c50000;
    border-top: 1px solid #c50000;
    height: 5px;
    background-color: #f8f8f8;
}
/* ......................... */
.support-container h6 {
  font-size: larger;
  font-weight: bold;
  margin-left: 74px;
}

/* Contact information container */
.support-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  border: 1px solid dodgerblue;
}

/* Individual support item styling */
.support-item {
  margin: 15px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

/* Icon styling */
.support-icon {
  margin-right: 10px;
  font-size: 25px;
  color: #0073e6;  /* Adjust the color of the icons */
}

/* Text styling for support details */
.support-text {
  font-size: 18px;
  color: #333;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .support-container h1 {
    font-size: 2rem;
  }

  .support-container h4 {
    font-size: 1rem;
  }

  .support-item {
    font-size: 14px;
  }
}
