.print-paper-summary-container {
    font-family: 'Arial', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .page-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .error-message {
    color: #f44336;
    text-align: center;
    font-size: 1.1rem;
    margin: 20px;
  }
  
  .paper-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .paper-table th,
  .paper-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .paper-table th {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
  }
  
  .paper-table tr:hover {
    background-color: #f4f4f4;
  }
  
  .paper-table td {
    font-size: 0.9rem;
    color: #555;
  }
  
  .download-button {
    display: inline-block;
    padding: 12px 30px;
    background-color: #28a745;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    width: 100%; 
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    margin-bottom: 50px;
  }
  
  .download-button:hover {
    background-color: #218838;
  }
  
  .download-button:focus {
    outline: none;
  }
  
  @media (max-width: 768px) {
    .paper-table th, .paper-table td {
      padding: 10px;
    }
  
    .page-title {
      font-size: 1.5rem;
    }
  
    .download-button {
      width: 100%;
      padding: 15px;
    }
  }
  
  @media (max-width: 480px) {
    .print-paper-summary-container {
      padding: 15px;
    }
  
    .page-title {
      font-size: 1.3rem;
    }
  
    .paper-table {
      font-size: 0.8rem;
    }
  
    .download-button {
      padding: 15px;
      font-size: 1.2rem;
    }
  }
  