.thank-you-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 50px 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 150px;
  }
  
  .thank-you-container h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .thank-you-container p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 30px;
  }
  
  .thank-you-container button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .thank-you-container button:hover {
    background-color: #0056b3;
  }
  