.submit-form-container input{
  margin-left: 20px;
}
.submit-form-container textarea{
  margin-left: 20px;
}
.submit-form-container label{
  margin-bottom: 10px;
}
.submit-form-container.header {
  flex: 20%;
  display: grid;
  place-items: center;
}

.submit-form-container .body {
  background-color: #ffffff;
  flex: 60%;
}

.submit-form-container .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
}

.submit-form-container .footer button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: rgb(255, 0, 140);
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}



/* PROGRESS BAR */
.submit-progressbar {
  width: 90%;
  height: 8px;
  background-color: white;
  border-radius: 40%;
}

.submit-progressbar div {
  width: 33.3%;
  height: 100%;
  background-color: rgb(101, 26, 221);
  border-radius: 30px;
}



.add{
margin-left: 5%;
}
.submit-authorContainer{
  width: 90%;
	background: #FAFAFA;
	padding: 20px;
	margin: 25px auto;
	box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.35);
	border-radius: 10px;
	border: 6px solid #e9e9e9;
}
.alarm{
  background-color: red;
}
.height{
  height: 150px;
}
.submit-authorForm input{
  width: 320px;
    height: 40px;
}
.uploadPaper{
  width: 740px;
}
.my-4 {

  padding-bottom: 50px;
}
