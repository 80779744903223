/* AddReviewer.css */

.add-reviewer-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-reviewer-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.add-reviewer-container form {
    display: flex;
    flex-direction: column;
}

.add-reviewer-container input[type="text"],
.add-reviewer-container input[type="email"],
.add-reviewer-container select {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.add-reviewer-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.add-reviewer-container button:hover {
    background-color: #0056b3;
}

.password-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-container h3 {
    margin-bottom: 10px;
}

.password-container .password-input-wrapper {
    display: flex;
    align-items: center;
}

.password-container input[type="text"].password-field {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
    width: 300px;
    text-align: center;
}

.password-container button.copy-button {
    background-color: #28a745;
}

.password-container button.copy-button:hover {
    background-color: #218838;
}

.psdbutton {
    background: black!important;
    width: fit-content;
    margin-bottom: 7px;
}