/* Modal Overlay */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Styling */
.modal-content {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 12px;
  width: 450px;
  max-width: 100%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  font-family: 'Arial', sans-serif;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  background-color: #dc3545;
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #c82333;
}

/* Title */
.modal-content h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

/* Strong labels */
.modal-content strong {
  color: #333;
  display: inline-block;
  width: 100px;
}

/* Spacing between the fields */
.modal-content div {
  margin-bottom: 10px;
}

/* Button for Show CV */
.btn-show-cv {
  background-color: #17a2b8;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.btn-show-cv:hover {
  background-color: #138496;
}



/* Styling for the action buttons */
.action-buttons {
  display: flex;
  gap: 0.75rem; /* Adds space between the buttons */
}

/* General Button Styling */
.btn {
  min-width: 90px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: white;
}

/* Specific Button Colors */
.btn-info {
  background-color: #007bff;
}

.btn-info:hover {
  background-color: #0056b3;
}

.btn-success {
  background-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
}

.btn-danger {
  background-color: #dc3545;
}

.btn-danger:hover {
  background-color: #c82333;
}
