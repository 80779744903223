.signin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.signin-box {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.signin-icon {
    background-color: #a64af9;
    color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px auto;
}

.signin-icon i {
    font-size: 24px;
}

h2 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.signin-form {
    display: flex;
    flex-direction: column;
}

.signin-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

.signin-button {
    padding: 10px;
    background-color: #1a73e8;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;
}

.signin-button:hover {
    background-color: #1558c0;
}

.signin-error {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

.signin-footer {
    font-size: 14px;
    color: #555555;
}

.signin-footer a {
    color: #1a73e8;
    text-decoration: none;
}

.signin-footer a:hover {
    text-decoration: underline;
}
