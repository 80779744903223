

.submission-form {
    margin: auto;
    background: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
}

.form-label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
    color: #333;
}

.form-textarea,
.form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s ease;
}

.form-textarea:focus,
.form-input:focus {
    border-color: #007BFF;
    outline: none;
}

.add-area-button {
    background-color: #6c757d;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.add-area-button:hover {
    background-color: #5a6268;
}

.submit-button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.submit-button:hover {
    background-color: #0056b3;
}
