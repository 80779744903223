/* .Blockquote{
    background-image : linear-gradient(to right, #edf5ef63, #f78d7a99);
}
.blockquote{
    padding: 10px 55px;
    background-color : #FAFAF9;
    border-radius: 25px;
    color: black;
    box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
    0 0  0 2px rgb(255,255,255) , 0.3em 0.3em 1em rgba(0,0,0,0.3);
  }; */
  body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  
  .sidebar{
    width: 260px !important;
    flex-shrink: 0;
    /* background-color: rgba(22, 22, 22, 1); */
    height: 98%;
    overflow: scroll;
    text-align: left;
    color: black;
  }
  
  
  .sidebar-item{
    padding: .75em 1em;
    display: block;
    transition: background-color .15s;
    border-radius: 5px;
  }
  .sidebar-item:hover{
    background-color: rgba(255, 255, 255, .1);
    text-decoration: none !important;
  }
  
  .sidebar-title{
    display: flex;
    font-size: 1.2em;
  }
  .sidebar-title span i{
    display: inline-block;
    width: 1.5em;
  }
  .sidebar-title .toggle-btn{
    cursor: pointer;
    transition: transform .3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn{
    transform: rotate(180deg);
  }
  .sidebar-content{
    padding-top: .25em;
    height: 0;
    overflow: hidden;
    margin-top: auto;
  }
  .sidebar-item.open > .sidebar-content{
    height: auto;
  }
  
  .sidebar-item.plain{
    color: #ffffff;
    text-decoration: none;
  }
  .sidebar-item.plain:hover{
    text-decoration: underline;
  }
  .sidebar-item.plain i{
    display: inline-block;
    width: 1.7em;
  }