/* General Page Styling */
.paper-list {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .all-paper-title {
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Paper List Items */
  .paper-item {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .paper-item h2 {
    text-decoration: none;
    font-size: 1em;
    margin: 0;
    color: #333;
    flex: 1;
  }
  
  /* Buttons */
  .details-button,
  .delete-button {
    padding: 8px 12px;
    font-size: 0.9em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .details-button {
    background-color: #007bff;
    color: white;
    margin-right: 10px;
  }
  
  .details-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  
  /* Loading and Error States */
  .paper-list p {
    text-align: center;
    font-size: 1.1em;
    color: #666;
  }
  

  .pagination {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 45px;
  }
  
  .pagination-button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination button {
    padding: 8px 12px;
    margin: 0 2px;
    border: none;
    background-color: #edeaea;
    color: #333;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination button.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .pagination button:hover:not(.active):not(:disabled) {
    background-color: #007bff;
  }