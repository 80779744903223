.registration-container {
    margin: 100px auto;
    max-width: 800px;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

.process-section, .fees-section {
    margin-bottom: 50px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.process-section h1, .fees-section h1 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #0044cc;
    border-bottom: 2px solid #0044cc;
    padding-bottom: 10px;
}

.process-section ol {
    padding-left: 20px;
}

.process-section li {
    font-size: 16px;
    padding: 10px 0;
    color: #333;
    border-bottom: 1px solid #e0e0e0;
}

.process-section li:last-child {
    border-bottom: none;
}

.fees-section table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.fees-section th, .fees-section td {
    border: 1px solid #e0e0e0;
    padding: 12px 15px;
    text-align: left;
    color: #333;
}

.fees-section th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.fees-section td {
    background-color: #fff;
}

.fees-section tr:nth-child(even) td {
    background-color: #f9f9f9;
}
