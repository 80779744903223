html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  padding-top: 20px; /* Adjust as needed */
  min-height: calc(100% - 70px); /* Adjust based on footer height */
  /* 100% - footer height */
  /* The footer height should be subtracted from the total height */
  /* This ensures the footer always stays at the bottom */
}

.main-footer {
  width: 100%;
  position: fixed;
  bottom: 0px;
}

/* Optionally, you can style the footer content */
.main-footer p {
  text-align: center;
  margin-bottom: 0;
}
