.sidebar{
    z-index: 10;
    margin-top: 88px;
    width: 200px;
    flex-shrink: 0;
    background-color: rgba(22, 22, 22, 1);
    height: 98%;
    overflow: scroll;
    text-align: left;
    color: rgb(255, 255, 255);
    position: fixed;
}
.ReviewerMainComponent{
    margin-left: auto;
    width: 1300px;
    margin-top: 100px;
}