/* SpeakerList.css */

.speaker-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: left; /* Center content horizontally */
}

.speaker-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.speaker-details {
  flex: 1;
}

.speaker-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.speaker-position {
  font-style: italic;
}

.speaker-university {
  margin-top: 5px;
}

.speaker-actions {
  margin-left: 20px;
}

.remove-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #c0392b;
}
