/* Modal Container */
#reviewerReview{
  width: 1000px;
    margin-left: 150px 
}
 .modal5 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  /* Modal Content */
    .modal-content2 {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 70%;
    height: fit-content;
    margin-left: 160px;
  }
  
  /* Modal Title */
    .modal-content2 h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Table Styles */
  #reviewerReview  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  #reviewerReview th,
    .table td {
    padding: 8px;
    text-align: left;
  }
  
  /* Close Button */
    .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
    .btn-primary:hover {
    background-color: #0056b3;
  }
  