/* Container styling */
.accommodation-details-container {
    max-width: 1150px!important;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .accommodation-details-container h1 {
    margin-top: 85px;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Accommodation list styling */
  .accommodation-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  /* Individual item styling */
  .accommodation-item {
    text-align: left; /* Set default alignment */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  /* Title styling */
  .accommodation-title {
    text-align: center; /* Center the title */
    font-size: 1.5rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Image styling */
  .accommodation-image {
    display: block; /* Ensures centering */
    width: min-content;
    height: auto;
    margin: 0 auto 15px auto; /* Center image and add bottom margin */
    border-radius: 5px;
  }
  
  /* Description styling */
  .accommodation-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin: 0;
    text-align: left; /* Left-align description */
  }
  